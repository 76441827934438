import React from 'react'
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import BlockContent from '@sanity/block-content-to-react';

import { ReactTypeformEmbed } from 'react-typeform-embed';

// import cornerIconQuery from '../queries/cornerIconQuery'

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

import Header from '../components/Header';
import CornerIcons from '../components/CornerIcons';
import SignUpPageVideo from '../components/SignUpPageVideo';
import Cursor from '../components/Cursor';




const buttonTextColor = (props) => {
  if (props.pageData.button_color) {
    return `
      rgba( ${props => props.pageData.button_color.rgb.r}, 
        ${props => props.pageData.button_color.rgb.g},
        ${props => props.pageData.button_color.rgb.b},
        ${props => props.pageData.button_color.rgb.a} )
      `  
  }
  else {
    return '#fff'
  }
}

const SignUpStyles = styled.div`
        height: 100vh;
        @media ${device.$medium} {
          height: 100%;
        }
        main {
            height: 100%;
            padding-top: var(--header-height--desktop);
            padding-bottom: 24px;
            text-align: center;
            display: flex;
            flex-direction: column;
            .description-blurb {
                margin-bottom: 42px;
                @media ${device.$medium} {
                  padding-left: 32px;
                  padding-right: 32px;
                }
            }
            .content {
                /* border: 1px solid red; */
                flex-grow: 1;
                position: relative;
                @media ${device.$medium} {
                  height: 100vh;
                  margin-bottom: 96px;
                }
                .button {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 30;
                  color: ${props => buttonTextColor(props)};
                  border: 2px solid ${props => buttonTextColor(props)};
                  min-height: 56px;
                  min-width: 175px;
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  transition: .2s all ease-in-out;
                  font-weight: 700;
                  &:hover {
                    background-color: #fff;
                    color: #000;
                  }
                }
            }
            /* change this classname & element, only used for testing */
            .hide-form {
                background: #fff;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 20;
                cursor: pointer;
                transition: .3s all ease-in-out;
                video,
                .vimeo-feature,
                mux-player {
                  height: 100%;
                  width: auto;
                }
                .vimeo-feature {
                  pointer-events: none;
                }
                &.desktop {
                  @media ${device.$medium} {
                    display: none;
                  }
                }
                &.mobile {
                  display: none;
                @media ${device.$medium} {
                    display: block;
                    height: auto;
                    margin-bottom: 12px;
                    position: relative;
                    top: initial;
                    left: initial;
                  }
                }
            }
            .typeform-wrapper {
              transition: .3s all ease-in-out;
            }
        }
`

class SignUpPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          cornerIconData: this.props.data.cornerIconData.nodes[0],
          signUpPageData: this.props.data.signUpPageData.nodes[0],
          siteSettingsData: this.props.data.siteSettings.nodes[0],
          hideTypeForm: true,
        };
    }
  
    componentDidMount() {
      // console.log('props', this.props)
    }

    handleTypeformReveal = () => {
        this.setState({ hideTypeForm: false })
    }

    render() {

      const data = this.state.signUpPageData;

      const typeformUrl = this.state.signUpPageData.typeform_url + '?typeform-welcome=0';
    
        return (
          <>
            <Helmet title="Sign Up | Mimi Casting"/>
            <SignUpStyles pageData={data}>
                <Header data={this.state.siteSettingsData}/>
                <CornerIcons data={this.state.cornerIconData} siteSettings={this.state.siteSettingsData}/>
                <Cursor 
                  staticText={this.state.siteSettingsData.cursor_static}
                  staticTextColor={this.state.siteSettingsData.cursor_static_color.hex}
                  cursorSparkle={this.state.siteSettingsData.cursor_sparkle}
                />
                <main className="container--clear-icons">
                    <BlockContent 
                    className="description-blurb"
                    blocks={this.state.signUpPageData._rawBlurb} 
                    renderContainerOnSingleChild={true}
                    />
                    <div className="content">
                        {
                            this.state.hideTypeForm &&
                            <>
                              {
                                data.button_label &&
                                <button className="button btn--none" onClick={this.handleTypeformReveal}>{data.button_label}</button>
                              }
                              {
                                data.enable_video &&
                                <SignUpPageVideo data={data} />
                              }
                              {
                                !data.enable_video &&
                                <>
                                  <GatsbyImage objectFit="contain" image={data.desktop_image.asset.gatsbyImageData} className="hide-form desktop" onClick={this.handleTypeformReveal}/>
                                  <GatsbyImage objectFit="contain" image={data.mobile_image.asset.gatsbyImageData} className="hide-form mobile" onClick={this.handleTypeformReveal}/>
                                </>
                              }
                            </>
                        }
                        {
                          !this.state.hideTypeForm &&
                            <div className="typeform-wrapper">
                              <ReactTypeformEmbed url={typeformUrl} />
                            </div>
                        }
                        
                    </div>
                </main>
            </SignUpStyles>
          </>
        )
    }

}

export default SignUpPage


export const query = graphql`
query {
    cornerIconData: allSanityCornerIcons {
        nodes {
          corner_icon__top_left_url
          corner_icon__top_right_url
          corner_icon__bottom_left_url
          corner_icon__bottom_right_url
          corner_icon__top_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__top_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
        }
    }
    signUpPageData: allSanitySignUpPage {
    nodes {
      _rawBlurb
      desktop_image {
        asset {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      mobile_image {
        asset {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      typeform_url
      button_label
      button_color {
        rgb {
          r
          g
          b
          a
        }
      }
      enable_video
      video_vimeo
      video {
        mux_video {
          _rawAsset(resolveReferences: {maxDepth: 10})
          asset {
            playbackId
          }
        }
        video_file {
          asset {
            url
            mimeType
          }
        }
      }
    }
  }
  siteSettings: allSanitySiteSettings {
    nodes {
      logo {
        asset {
          extension
          gatsbyImageData(width: 300, layout: CONSTRAINED)
          url
        }
      }
      site_hover_color {
        rgb {
          r
          g
          b
          a
        }
      }
      cursor_sparkle
      cursor_static
      cursor_static_color {
        hex
      }
    }
  }
}
`