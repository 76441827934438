import React from 'react'
import MuxPlayer from "@mux/mux-player-react";

import styled from 'styled-components';

import { device } from '../styles/Breakpoints.js';

const VideoStyles = styled.div`
    width: 100%;
    background-color: #dadaad;
    video,
    mux-player {
        max-width: 100%;
    }
    @media ${device.$medium} {
    }

    mux-player {
        --controls: none;
        --media-object-fit: cover;
        --media-object-position: center;
    }
`

export default function SignUpPageVideo({data}) {
    console.log('data:', data)
    

    return (
        <VideoStyles className="hide-form">
            {
                data.video && data.video.mux_video &&
                    <MuxPlayer
                        className="internal-feature"
                        playbackId={data.video.mux_video.asset.playbackId}
                        streamType="on-demand"
                        muted={true}
                        autoPlay
                        loop
                    />
                
            }
            {
                data.video && data.video.video_file && !data.video.mux_video &&
                <video playsInline autoPlay muted loop className="internal-feature">
                    <source src={data.video.video_file.asset.url} type={data.video.video_file.asset.mimeType} ></source>
                </video>
            }
            {
                !data.video &&
                <div className="vimeo-feature" dangerouslySetInnerHTML={{ __html: data.video_vimeo }}></div>
            }
            

        </VideoStyles>
    )
}
